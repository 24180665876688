/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";

import axios from "axios";
import slugify from "react-slugify";
import { toast } from "react-toastify";
import Backend from "../../../utils/Backend";
import useMobile from "../../Hooks/Mobile";
import useScrolledTo from "../../Hooks/ScrolledTo";
import { formatFromPrice, formatRange } from "../../../helper/vehicle_formatter";
import { featuredVehicleEvent } from "../../../utils/Analytics";

import Loader from "../../Loader";

import styles from "./styles.module.scss";
import ArrowIcon from '../../../public/assets/icons/right-arrow.svg'
import BoiArrowIcon from "../../../public/assets/nevo-boi/chevron-right.svg";
import { useIsBoiWebsite } from "../../Context/WebsiteType";

export default function PopularVehicles() {
	const [vehicles, setVehicles] = useState<any[]>([]);
	const [images, setImages] = useState<string[]>([]);

	const sectionRef = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState(true);

	const [car, setCar] = useState(0);

	const leftLeftImageRef = useRef<HTMLDivElement>(null);
	const leftImageRef = useRef<HTMLDivElement>(null);
	const imageRef = useRef<HTMLDivElement>(null);
	const rightImageRef = useRef<HTMLDivElement>(null);
	const rightRightImageRef = useRef<HTMLDivElement>(null);

	const mobile = useMobile(840);

	const fetchData = useScrolledTo(sectionRef);

	const isBoiWebsite = useIsBoiWebsite();

	useEffect(() => {
		if (fetchData) {
			const { promise, cancel } = Backend.getFeaturedVehicles();

			promise
				.then(({ data }) => {
					if (Object.keys(data?.content)?.length !== 0) {
						setVehicles(data.content);
					} else {
						toast.error("No featured vehicles found");
					}

					setLoading(false);
				})
				.catch((err) => {
					if (axios.isCancel(err)) return;

					console.error("Falied to retrieve featured vehicles");
					toast.error("Failed to retrieve featured vehicles");

					setLoading(false);
				});

			return cancel;
		}
	}, [fetchData]);

	// fetch images for all featured vehicles
	useEffect(() => {
		if (vehicles.length > 0) {
			const imagesURL: string[] = [];

			vehicles.forEach((vehicle) => {
				const url = Backend.getImage(vehicle);
				imagesURL.push(url);
			});

			setImages(imagesURL);
		}
	}, [vehicles]);

	// vehicle auto-scrolling animation
	useEffect(() => {
		if (vehicles.length <= 0) return;

		const handleNextVehicle = () => {
			if (car + 1 < vehicles.length) {
				imageAnimation(car + 1, true);
			} else imageAnimation(0, true);
		};

		const timeout = setTimeout(handleNextVehicle, 2500);
		return () => clearTimeout(timeout);
	}, [car, vehicles]);

	const imageAnimation = (newCar: number, direction: boolean) => {
		// to the right

		const leftLeftImage = direction ? "" : styles.imageNext;
		const leftImage = direction ? styles.leftImagePrev : styles.leftImageNext;
		const image = direction ? styles.imageNext : styles.imagePrev;
		const rightImage = direction ? styles.rightImageNext : styles.rightImagePrev;
		const rigthRightImage = direction ? styles.imagePrev : "";

		if (!direction) leftLeftImageRef.current?.classList.add(leftLeftImage);

		leftImageRef.current?.classList.add(leftImage);
		imageRef.current?.classList.add(image);
		rightImageRef.current?.classList.add(rightImage);

		if (direction) rightRightImageRef.current?.classList.add(rigthRightImage);

		setTimeout(() => {
			if (!direction) leftLeftImageRef.current?.classList.remove(leftLeftImage);

			leftImageRef.current?.classList.remove(leftImage);
			imageRef.current?.classList.remove(image);
			rightImageRef.current?.classList.remove(rightImage);

			if (direction) rightRightImageRef.current?.classList.remove(rigthRightImage);

			setCar(newCar);
		}, 475);
	};

	const prevCar = car - 1 < 0 ? vehicles.length - 1 : car - 1;
	const nextCar = car + 1 > vehicles.length - 1 ? 0 : car + 1;

	const prevPrevCar = prevCar - 1 < 0 ? vehicles.length - 1 : prevCar - 1;
	const nextNextCar = nextCar + 1 > vehicles.length - 1 ? 0 : nextCar + 1;

	const getVehicleURL = () => {
		if (vehicles.length > 0) {
			const make = slugify(vehicles[car].make);
			const model = slugify(vehicles[car].model);
			const vehicle_id = vehicles[car].id;

			return `/vehicle/${make}/${model}/${vehicle_id}`;
		}
		return "/";
	};

	const trackVehicle = (vehicle: any) => {
		featuredVehicleEvent(isBoiWebsite, vehicle.id);
	};

	let rangeText: string | JSX.Element = "";
	if (vehicles[car]?.range > 0) {
		rangeText = `Ranges up to ${vehicles[car].range}km ${formatRange(vehicles[car].fuel_type)}`;

		if (mobile) {
			rangeText = (
				<>
					<br />
					{rangeText}
				</>
			);
		} else {
			rangeText = `/ ${rangeText}`;
		}
	}

	return (
		<div className={styles.nextCar} ref={sectionRef}>
			<h1>Popular Vehicles</h1>

			{loading ? (
				<div className={styles.holder}>
					<Loader />
				</div>
			) : (
				<div className={styles.vehicles}>
					{vehicles.length === 0 ? (
						<div className={styles.holder}>
							<h2 className={styles.errorMessage}>Feature vehicles not found</h2>
						</div>
					) : (
						<>
							<div className={styles.vehicleCarousel}>
								{images.length > 0 ? (
									<>
										{!mobile && (
											<div
												className={`${styles.leftHidden} ${styles.animationImage} ${styles.vehicleImage} `}
												ref={leftLeftImageRef}
												style={{ backgroundImage: `url(${images[prevPrevCar]})` }}
											/>
										)}

										<div
											className={`${styles.leftImage} ${styles.animationImage} ${styles.vehicleImage} `}
											ref={leftImageRef}
											style={{ backgroundImage: `url(${images[prevCar]})` }}
											onClick={() => imageAnimation(prevCar, false)}
										/>

										<div className={`${styles.arrowHolder} ${styles.left}`} onClick={() => imageAnimation(prevCar, false)}>
											<img className={styles.leftArrow} src={isBoiWebsite ? BoiArrowIcon.src : ArrowIcon.src} alt="arrow" />
										</div>

										<Link href={getVehicleURL()} passHref>
											<div className={styles.vehicleImage} ref={imageRef} style={{ backgroundImage: `url(${images[car]})` }} onClick={() => trackVehicle(vehicles[car])} />
										</Link>

										<div className={`${styles.arrowHolder} ${styles.right}`} onClick={() => imageAnimation(nextCar, true)}>
											<img className={styles.rightArrow} src={isBoiWebsite ? BoiArrowIcon.src : ArrowIcon.src} alt="arrow" />
										</div>

										<div
											className={`${styles.rightImage} ${styles.animationImage} ${styles.vehicleImage} `}
											ref={rightImageRef}
											style={{ backgroundImage: `url(${images[nextCar]})` }}
											onClick={() => imageAnimation(nextCar, true)}
										/>

										{!mobile && (
											<div
												className={`${styles.rightHidden} ${styles.animationImage} ${styles.vehicleImage} `}
												ref={rightRightImageRef}
												style={{ backgroundImage: `url(${images[nextNextCar]})` }}
											/>
										)}
									</>
								) : (
									<Loader />
								)}
							</div>

							<Link href={getVehicleURL()} passHref>
								<div className={styles.details} onClick={() => trackVehicle(vehicles[car])}>
									<h2 className={styles.vehicleTitle}>{`${vehicles[car].make} ${vehicles[car].model}`}</h2>

									<p className={styles.vehicleInfo}>
										{formatFromPrice(vehicles[car].price)} {rangeText}
									</p>
								</div>
							</Link>
						</>
					)}

					<div className={styles.controls}>
						<Link href={getVehicleURL()} passHref>
							<button className={`${isBoiWebsite ? 'dark-blue-button-boi' : 'blue-button'}`} onClick={() => trackVehicle(vehicles[car])}>
								View car details
							</button>
						</Link>
					</div>
				</div>
			)}
		</div>
	);
}
